import {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
} from "react";
import styles from "@/components/Programs/Sidebar/ProgramsSidebar.module.scss";
import ArrowIcon from "@/public/images/symbols/right.svg";
import FormCheck from "@/components/_Common/Form/Checkbox";
import { IDictionaryItem } from "@/lib/services/dictionary.service";
import useTranslation from "next-translate/useTranslation";
import clsx from "clsx";
import RangeBar from "@/components/_Common/RangeBar";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import RadioButton from "@/components/_Common/RadioButton";
import { Key } from "react";
import DegreeEnum from "@/lib/enums/degree";

type ProgramsFilterProps = {
  variant:
  | "directions"
  | "disciplines"
  | "opportunities"
  | "price"
  | "types"
  | "faculties"
  | "languages";
  showFilter: boolean;
  toggleShowFilter: () => void;
  items?: IDictionaryItem[];
  filterValues: IFilters;
  setFilterValues: (filterValues: IFilters) => void;
  isMobile?: boolean;
};

const ProgramsFilter: FC<PropsWithChildren<ProgramsFilterProps>> = (
  props
): JSX.Element => {
  const {
    variant,
    showFilter,
    toggleShowFilter,
    items,
    setFilterValues,
    filterValues,
    isMobile,
  } = props;
  const { studentDetailActive } = useTypedSelector((state) => state.user);
  const [priceRange, setPriceRange] = useState([0, 0]);
  const { degree } = studentDetailActive;
  const { t } = useTranslation("programs");

  useEffect(() => {
    switch (degree) {
      case DegreeEnum.BACHELOR:
        setPriceRange([250000, 459000]);
        break;
      case DegreeEnum.MASTER:
        setPriceRange([289000, 650000]);
        break;
      default:
        setPriceRange([289000, 599000]);
    }
  }, [studentDetailActive]);

  const handleChange = (value: string, e: ChangeEvent<HTMLInputElement>) => {
    const newFilterValues = { ...filterValues };

    if (variant !== "price" && variant !== "languages") {
      if (e.target.checked && variant) {
        newFilterValues[variant].push(value);
        setFilterValues(newFilterValues);
      } else {
        if (variant)
          newFilterValues[variant] = newFilterValues[variant].filter(
            (item) => item !== value
          );
        setFilterValues(newFilterValues);
      }
    }

    if (variant === "languages") {
      newFilterValues[variant] = value;
      setFilterValues(newFilterValues);
    }
  };

  const updatePrices = (values: number[]) => {
    const newFilterValues = { ...filterValues };
    newFilterValues["priceFrom"] = values[0] + "";
    newFilterValues["priceTo"] = values[1] + "";
    setFilterValues(newFilterValues);
  };

  useMemo(() => {
    updatePrices;
  }, []);

  const resetFilter = () => {
    const newFilterValues = { ...filterValues };

    if (variant !== "price") {
      // @ts-ignore
      newFilterValues[variant] = [];
    }

    toggleShowFilter();
    setFilterValues(newFilterValues);
  };

  return (
    <>
      <div
        id={`programsSidebarItem_${variant}`}
        className={clsx(
          styles.filter__item,
          variant === "disciplines" && degree === "postgraduate" && "d-none",
          isMobile && styles.filter__item_mobile
        )}
        onClick={toggleShowFilter}
      >
        <p className={styles.filter__text}>{t(`filter.items.${variant}`)}</p>
        <ArrowIcon
          className={clsx(
            styles.filter__icon,
            showFilter && styles.filter__icon_rotate
          )}
        />
      </div>
      <div
        id={`programsSidebarItemChecks_${variant}`}
        className={clsx(
          styles.filter__checks,
          isMobile && styles.filter__checks_mobile
        )}
      >
        {showFilter && isMobile && (
          <p className={styles.filter__reset} onClick={resetFilter}>
            {t("filter.reset")}
          </p>
        )}
        {showFilter &&
          variant !== "price" &&
          variant !== "languages" &&
          items?.map(({ value, label }, index) => (
            <FormCheck
              additionalStyles={clsx(styles.filter__checkbox)}
              defaultChecked={filterValues[variant]?.includes(value as string)}
              id={value as string}
              key={index}
              name={value as string}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(value as string, e)
              }
            >
              {label}
            </FormCheck>
          ))}
        {showFilter &&
          variant === "languages" &&
          items?.map(({ value, label }, index) => (
            <RadioButton
              id={`radioOption${index}`}
              key={value as Key | null | undefined}
              name="radioGroup"
              checked={
                variant === "languages"
                  ? (filterValues as any)[variant] === String(value)
                  : value != null && (filterValues as any)[variant]?.includes(value.toString())
              }
              onChange={(e) => value != null && handleChange(value.toString(), e)}
            >
              {label}
            </RadioButton>
          ))}
        {showFilter && variant !== "languages" && variant === "price" && (
          <RangeBar
            max={priceRange[1]}
            min={priceRange[0]}
            updatePrices={updatePrices}
          />
        )}
      </div>
    </>
  );
};

export default ProgramsFilter;
